/**
 * Reference:
 * https://www.npmjs.com/package/amazon-cognito-identity-js
 * https://docs.aws.amazon.com/es_es/cognito/latest/developerguide/using-amazon-cognito-user-identity-pools-javascript-examples.html
 */

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"
import jwtDecode from "jwt-decode"

import { isAdmin, isUserAuthorized } from "./authorization"

import { CONSTANTS } from "../constants"
import { navigate } from "gatsby-plugin-intl"
import { getUserPermissions } from "./permissions/userPermissions"
import fetchUtils from "../../utils/fetch-utils"

const { LOGIN_PAGE, LOGGED_IN_INITIAL_PAGE } = CONSTANTS

const COGNITO_GROUPS_JWT_TOKEN_KEY = "cognito:groups"

function _setWindowPathname(pathname) {
  if (
    typeof window !== "undefined" &&
    window &&
    window.location &&
    window.location.pathname
  ) {
    window.location.pathname = pathname
  }
}

function _generateAuthenticationDetails(username, password) {
  return new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: password,
  })
}

function _getCognitoUserPool() {
  return new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
  })
}

function _generateUserData(username) {
  return {
    Username: username,
    Pool: _getCognitoUserPool(),
  }
}

function _loginCognitoFailure(error) {
  switch (error.code) {
    case "UserNotFoundException":
      return { code: AUTH_CONSTANTS.NO_USER_ERROR }
    case "NotAuthorizedException":
      if (error.message.includes("expired")) {
        return { code: AUTH_CONSTANTS.EXPIRED_CODE }
      }
      return { code: AUTH_CONSTANTS.PASSWORD_IS_WRONG }
    default:
      return { code: AUTH_CONSTANTS.FALLBACK_AUTH_ERROR }
  }
}

function _loginCognito(username, password) {
  const authenticationDetails = _generateAuthenticationDetails(
    username,
    password
  )
  const userData = _generateUserData(username)
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

  return new Promise((ok, ko) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async () => {
        await getUserPermissions()
        ok(username)
      },
      onFailure: error => {
        ko(_loginCognitoFailure(error))
      },
      newPasswordRequired: userAttributes => {
        delete userAttributes.email_verified
        delete userAttributes.email
        const newPasswordRequiredFunction = newPassword => {
          return new Promise((ok, ko) => {
            cognitoUser.completeNewPasswordChallenge(
              newPassword,
              userAttributes,
              {
                onSuccess: ok,
                onFailure: ko,
              }
            )
          })
        }
        ko({
          code: AUTH_CONSTANTS.NEW_PASSWORD_ERROR,
          newPasswordRequiredFunction,
        })
      },
    })
  })
}

/**
 *  ______________________
 *  |                    |
 *  |     PUBLIC API     |
 *  |                    |
 *  ----------------------
 */

export const AUTH_CONSTANTS = {
  NEW_PASSWORD_ERROR: "NewPasswordRequired",
  NO_USER_ERROR: "NoExistingUserError",
  PASSWORD_IS_WRONG: "PasswordIsWrongError",
  PASSWORD_DOES_NOT_MEET_CRITERIA_ERROR: "InvalidPasswordException",
  FALLBACK_AUTH_ERROR: "FallbackAuthError",
  INVALID_FORMAT: "InvalidParameterException",
  EXPIRED_CODE: "ExpiredCodeException",
  LIMIT_EXCEEDED_EXCEPTION: "LimitExceededException",
  INVALID_CODE: "CodeMismatchException",
}

export function getJwtTokenFromIdToken(session) {
  if (!session) {
    throw new Error('No "session" defined')
  } else {
    return session.idToken && session.idToken.getJwtToken()
  }
}

export function login(username, password) {
  if (username && password) {
    return _loginCognito(username, password)
  }
}

export function logout() {
  const cognitoUser = _getCognitoUserPool().getCurrentUser()
  if (cognitoUser) {
    cognitoUser.signOut()
    navigate("/")
  }
}

export function getSession() {
  const cognitoUser = _getCognitoUserPool().getCurrentUser()
  if (cognitoUser !== null) {
    return new Promise((ok, ko) => {
      cognitoUser.getSession(function(err, session) {
        if (!err && session.isValid()) {
          return ok(session)
        } else {
          return ko(err || "Invalid Session")
        }
      })
    })
  } else {
    return Promise.reject("No Cognito User found")
  }
}

export function isLoggedIn() {
  return getSession()
}

export function isAuthorized(page) {
  return getSession().then(session => {
    const decodedToken = jwtDecode(getJwtTokenFromIdToken(session))

    return isUserAuthorized(decodedToken[COGNITO_GROUPS_JWT_TOKEN_KEY], page)
  })
}

export function getUserGroups() {
  return getSession().then(
    session =>
      jwtDecode(getJwtTokenFromIdToken(session))[COGNITO_GROUPS_JWT_TOKEN_KEY]
  )
}

export function getCurrentUser() {
  return this.getUserPool().getCurrentUser()
}

function _getUserFromIdToken(idToken) {
  const decodedToken = jwtDecode(idToken)
  return {
    id: decodedToken["cognito:username"] || "",
    email: decodedToken.email || "",
    name: decodedToken["custom:custom_name"] || "",
    lastName: decodedToken["custom:custom_last_name"] || "",
    groups: decodedToken[COGNITO_GROUPS_JWT_TOKEN_KEY] || "",
  }
}

export function getUser() {
  return isLoggedIn()
    .then(session => {
      return _getUserFromIdToken(getJwtTokenFromIdToken(session))
    })
    .catch(reason => console.error("Error :: getUser -> ", reason))
}

export function navigateToHomePage() {
  _setWindowPathname(LOGGED_IN_INITIAL_PAGE)
}

export function navigateToLoginPage() {
  _setWindowPathname(LOGIN_PAGE)
}

export function isUserAdmin() {
  return getUserGroups().then(isAdmin)
}

export function recoverPassword(username) {
  const userData = _generateUserData(username)
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

  return new Promise((ok, ko) => {
    cognitoUser.forgotPassword({
      onSuccess: () => ok(),
      onFailure: err => ko(err),
    })
  })
}

export function confirmNewPassword(
  username,
  confirmationCode,
  password,
  callback
) {
  const userData = _generateUserData(username)
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
  cognitoUser.confirmPassword(confirmationCode, password, {
    onSuccess: () => callback(null),
    onFailure: err => callback(err),
  })
}

export async function resendPassword(username) {
  const request = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ username }),
  }
  const res = await fetch(
    `${process.env.GATSBY_RESEND_MESSAGE_API_URL}`,
    request
  )
  const data = await res.json()
  if (data.message === "FAILED") {
    throw data.error
  }
}

export const getSSOUrl = (type, code) => {
  const siteUrl = document.location.origin
  let result = siteUrl
  const clientId = process.env.GATSBY_COGNITO_CLIENT_ID
  const oauth2Url = `${process.env.GATSBY_COGNITO_API_URL}/oauth2`
  if (type === "token" && code) {
    result = `${oauth2Url}/token?grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${siteUrl}`
  } else if (type === "authorize") {
    const scopes = ["email", "openid", "profile"].join(" ")
    result = `${oauth2Url}/authorize?identity_provider=AzureAD&response_type=code&client_id=${clientId}&redirect_uri=${siteUrl}&scope=${scopes}`
  }
  return result
}

export const signInFromSSO = async code => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  }
  const init = {
    method: "POST",
    headers,
  }
  const urlToken = getSSOUrl("token", code)
  const data = await fetchUtils.fetchJson(urlToken, init)
  const { id_token, refresh_token, access_token } = data
  const user = _getUserFromIdToken(id_token)
  const userData = _generateUserData(user.id)
  const cognitoIdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: id_token,
  })
  const cognitoAccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: access_token,
  })
  const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: refresh_token,
  })
  const cognitoUserSession = new AmazonCognitoIdentity.CognitoUserSession({
    IdToken: cognitoIdToken,
    AccessToken: cognitoAccessToken,
    RefreshToken: cognitoRefreshToken,
  })
  if (cognitoUserSession.isValid()) {
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    cognitoUser.setSignInUserSession(cognitoUserSession)
  }
}

export const refreshSession = callback => {
  const cognitoUser = _getCognitoUserPool().getCurrentUser()
  getSession().then(session => {
    cognitoUser.refreshSession(session.getRefreshToken(), () => {
      callback()
    })
  })
}
