import authFetch from "../../network"
import SecureLS from "secure-ls"
import { ENCRIPTATION_OPTIONS, PERMISSION_KEY } from "./permissions"
import { message } from "antd"
import { navigate } from "gatsby-plugin-intl"

export function getUserPermissions() {
  return new Promise((ok, ko) => {
    authFetch(`${process.env.GATSBY_CONF_API_URL}/own-permissions`)
      .then(response => {
        if (200 === response.status) {
          response.json().then(permissions => {
            if (permissions.length === 0) {
              navigate("/403")
            }
            const ls = new SecureLS(ENCRIPTATION_OPTIONS)
            ls.set(PERMISSION_KEY, permissions)
            return ok(permissions)
          })
        } else {
          _setEmptyPermissions()
          return ok(response)
        }
      })
      .catch(error => {
        _setEmptyPermissions()
        return ko(error)
      })
  })
}

function _setEmptyPermissions() {
  const ls =
    typeof window !== "undefined" && window
      ? new SecureLS(ENCRIPTATION_OPTIONS)
      : null
  if (ls && ls.get && ls.get(PERMISSION_KEY)) {
    ls.set(PERMISSION_KEY, [])
  }
  message.error("[ERROR]: Getting list of permissions")
}
