import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Layout } from "antd"

const { Footer } = Layout

const CustomFooter = ({ isMarkdown }) => {
  const intl = useIntl()

  return (
    <Footer
      style={{
        textAlign: "center",
        backgroundColor: isMarkdown ? "white" : "unset",
      }}
    >
      Knolar. Powered by CEPSA © {new Date().getFullYear()} |{" "}
      <a href="/public/privacy-policy">
        {intl.formatMessage({
          id: "privacy-policy",
        })}
      </a>{" "}
      |{" "}
      <a href="/public/cookie-policy">
        {intl.formatMessage({
          id: "cookie-policy",
        })}
      </a>
    </Footer>
  )
}

export default CustomFooter
