import authFetch from "../services/network"

const fetchJson = (url, init = { method: "GET" }) => {
  return new Promise(async (resolve, reject) => {
    fetch(url, init)
      .then(response => {
        const { ok, statusText } = response
        if (!ok) {
          reject(statusText)
        } else {
          return response.json()
        }
        return null
      })
      .then(data => {
        if (data) {
          resolve(data)
        } else {
          reject()
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

const authFetchJson = (url, config = { method: "GET" }) => {
  return new Promise(async (resolve, reject) => {
    authFetch(url, config)
      .then(response => {
        if (response.status !== 200) {
          reject()
        } else {
          return response.json()
        }
      })
      .then(data => {
        if (data) {
          resolve(data)
        } else {
          reject()
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}

const fetchUtils = {
  fetchJson,
  authFetchJson,
}

export default fetchUtils
