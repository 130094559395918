import profiles from "./profiles"
import * as constants from "../../constants"

const { LOGIN_PAGE, LOGGED_IN_INITIAL_PAGE } = constants.CONSTANTS

const ADMIN_GROUP = "admin"

const windowGlobal = typeof window !== "undefined" && window

function _isGroupAuthorizedInPage(group, page) {
  return profiles[group].includes(page)
}

function _isAnyGroupAuthorizedInPage(groups, page) {
  if (
    groups.includes(ADMIN_GROUP) ||
    ["", LOGIN_PAGE, LOGGED_IN_INITIAL_PAGE].includes(page) ||
    groups.includes("viewer")
  ) {
    return true
  }
  // else {
  //   let authorized = false
  //   let index = 0

  //   while (!authorized) {
  //     authorized = _isGroupAuthorizedInPage(groups[index++], page)
  //   }

  //   return authorized
  // }
  return true
}

export function isUserAuthorized(
  groups = [],
  currentPage = windowGlobal.location
) {
  return _isAnyGroupAuthorizedInPage(groups, currentPage)
}

export function isAdmin(groups) {
  return groups.includes(ADMIN_GROUP)
}
