import { navigate } from "gatsby-plugin-intl"

import { isLoggedIn, getSession, getJwtTokenFromIdToken } from "./auth"

function _fetch(url, config = {}) {
  return getSession()
    .then((session = {}) => {
      const _config = Object.assign({}, config || {})
      const _headers = (_config && _config.headers) || {}
      const _body = (_config && _config.body) || {}

      _headers.auth = getJwtTokenFromIdToken(session)
      _config.method =
        typeof _config.method === "undefined" ? "GET" : _config.method

      const options = Object.assign({}, _config, {
        headers: new Headers(_headers),
      })

      if (!["GET", "HEAD"].includes(_config.method)) {
        options.body = typeof _body === "string" ? _body : JSON.stringify(_body)
      } else {
        delete options.body //Just in case
      }

      return options
    })
    .then(async options => {
      const response = await fetch(url, options)
      if (response.status === 403) {
        navigate("/403")
      }
      return response
    })
}

export default function authFetch(url = "", config) {
  if (!url) {
    throw new Error('No "url" defined')
  } else {
    return isLoggedIn().then(() => _fetch(url, config))
  }
}

// Values from '<project_root>/cfg/.xxx.env' files
const DEV_ENV = "dev"
const LOCAL_DEV_ENV = "local"

/**
 * FALLBACK_HOSTNAME might be a real development environment in the future. As of September 2020, we've been
 * developing and testing against this PRODUCTIVE account
 */
const FALLBACK_HOSTNAME = "dev.knolar.io"

/**
 * Function that generates a link to an external resource,
 * such as Jupyter or Superset, based on the production DNS name
 * where this distribution of CPH is deployed.
 *
 * Example:
 * DNS name of a distribution of CPH: https://manufacturing.arminio.cloud
 * DNS name of Jupyter for the previous distribution: https://jupyter-hub.manufacturing.arminio.cloud/
 * DNS name of Superset for the previous distribution: https://superset.manufacturing.arminio.cloud/
 *
 * The distribution / deployment is "manufacturing" and the external resources are 'jupyter-hub' and 'superset'
 * for each previous example.
 *
 * @param {string} externalResource - External resource whose distribution URL must be generated
 * @returns {string} - Distribution URL of the resource
 */
export function generateDeploymentHostname(externalResource = "") {
  switch (process.env.GATSBY_ENV_NAME) {
    case LOCAL_DEV_ENV:
    case DEV_ENV:
      return `${externalResource}.${FALLBACK_HOSTNAME}`
    default:
      const hostname =
        typeof window !== "undefined" &&
        window.location &&
        window.location.hostname

      return `${externalResource}.${hostname}`
  }
}
