// import authFetch from "../../network"
import SecureLS from "secure-ls"

const ENCRIPTATION_PERMISSION_KEY = "p3rm1$$ion$@key"
const ENCRIPTATION_TYPE = "rc4"
export const PERMISSION_KEY = "lock"
export const ENCRIPTATION_OPTIONS = {
  encodingType: ENCRIPTATION_TYPE,
  isCompression: false,
  encryptionSecret: ENCRIPTATION_PERMISSION_KEY,
}

export function getPermissions() {
  const ls =
    typeof window !== "undefined" && window
      ? new SecureLS(ENCRIPTATION_OPTIONS)
      : null
  if (ls && ls.get && ls.get(PERMISSION_KEY)) {
    return ls.get(PERMISSION_KEY)
  }
  return []
}

export function checkPermissions(permissionsName, some = false) {
  const ls =
    typeof window !== "undefined" && window
      ? new SecureLS(ENCRIPTATION_OPTIONS)
      : null
  if (ls && ls.get && ls.get(PERMISSION_KEY)) {
    const permissions = ls.get(PERMISSION_KEY)
    if (permissions && permissionsName) {
      if (_checkIfAdmin(permissions)) {
        return true
      } else {
        if (some) {
          return permissionsName.some(r => permissions.includes(r))
        }
        return permissionsName.every(r => permissions.includes(r))
      }
    }
  }
  return false
}

function _checkIfAdmin(permissions) {
  return permissions.includes("*")
}

// Permissions utils

function _getPermissionsModel() {
  return [
    {
      title: "menu-ingestions",
      type: "operation",
      permissions: [
        {
          name: "ingest:read",
          value: false,
          type: "read",
          disabled: false,
        },
        {
          name: "ingest:write",
          value: false,
          type: "write",
          required: ["ingest:read", "dictionary:read"],
          disabled: false,
        },
        {
          name: "ingest:delete",
          type: "delete",
          value: false,
          required: ["ingest:read"],
          disabled: false,
        },
        {
          name: "status:write",
          type: "activate",
          value: false,
          required: ["ingest:read"],
          disabled: false,
        },
      ],
    },
    {
      title: "dictionaries-entity",
      type: "operation",
      permissions: [
        {
          name: "dictionary:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "dictionary:write",
          type: "write",
          value: false,
          required: ["dictionary:read"],
          disabled: false,
        },
        {
          name: "dictionary:delete",
          type: "delete",
          value: false,
          required: ["dictionary:read"],
          disabled: false,
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "models-alarms",
      type: "operation",
      permissions: [
        {
          name: "alarm:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: null,
          type: "write",
          value: null,
          disabled: false,
        },
        {
          name: "alarm:delete",
          type: "delete",
          value: false,
          required: ["alarm:read"],
          disabled: false,
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "models-sources",
      type: "operation",
      permissions: [
        {
          name: "source:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "source:write",
          type: "write",
          value: false,
          disabled: false,
          required: ["source:read"],
        },
        {
          name: "source:delete",
          type: "delete",
          value: false,
          disabled: false,
          required: ["source:read"],
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "models-sites",
      type: "operation",
      permissions: [
        {
          name: "site:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "site:write",
          type: "write",
          value: false,
          disabled: false,
          required: ["site:read"],
        },
        {
          name: "site:delete",
          type: "delete",
          value: false,
          disabled: false,
          required: ["site:read"],
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "models-prefixes",
      type: "operation",
      permissions: [
        {
          name: "prefix:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "prefix:write",
          type: "write",
          value: false,
          disabled: false,
          required: ["prefix:read", "source:read", "site:read"],
        },
        {
          name: "prefix:delete",
          type: "delete",
          value: false,
          disabled: false,
          required: ["prefix:read"],
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "models-business-units",
      type: "operation",
      permissions: [
        {
          name: "business-unit:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "business-unit:write",
          type: "write",
          value: false,
          disabled: false,
          required: ["business-unit:read"],
        },
        {
          name: "business-unit:delete",
          type: "delete",
          value: false,
          disabled: false,
          required: ["business-unit:read"],
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "menu-user",
      type: "operation",
      permissions: [
        {
          name: "user:read",
          type: "read",
          value: false,
          error: true,
        },
        {
          name: "user:write",
          type: "write",
          value: false,
          required: ["user:read"],
          disabled: false,
        },
        {
          name: "user:delete",
          type: "delete",
          value: false,
          required: ["user:read"],
          disabled: false,
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "menu-role",
      type: "operation",
      permissions: [
        {
          name: "role:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "role:write",
          type: "write",
          value: false,
          required: ["role:read"],
          disabled: false,
        },
        {
          name: "role:delete",
          type: "delete",
          value: false,
          required: ["role:read"],
          disabled: false,
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "page-role-component-table-consume",
      type: "consume",
      permissions: [
        {
          title: "menu-jupyter",
          name: "jupyter:access",
          value: false,
          disabled: false,
          hint: "menu-jupyter-hint",
        },
        {
          title: "menu-superset",
          name: "superset:access",
          value: false,
          disabled: false,
          hint: "menu-superset-hint",
        },
        {
          title: "menu-grafana",
          name: "monitoring:access",
          value: false,
          disabled: false,
          hint: "menu-grafana-hint",
        },
        {
          title: "menu-druid",
          name: "druid-api:access",
          value: false,
          disabled: false,
          hint: "menu-druid-hint",
        },
        /*
        {
          title: "menu-datalake",
          name: "datalake:access",
          value: false,
          disabled: false,
        },
        */
        {
          title: "menu-developers-portal",
          name: "developers-portal:access",
          value: false,
          disabled: false,
          hint: "menu-developers-hint",
        },
        {
          title: "menu-excel-addin",
          name: "addin:access",
          value: false,
          disabled: false,
          hint: "menu-excel-addin-hint",
          permissions: ["addin:access", "addin-web:access"],
        },
      ],
    },
    {
      title: "menu-ml-dataset",
      type: "operation",
      permissions: [
        {
          name: "dataset:read",
          type: "read",
          value: false,
          disabled: false,
        },
        {
          name: "dataset:write",
          type: "write",
          value: false,
          required: ["dataset:read"],
          disabled: false,
        },
        {
          name: "dataset:delete",
          type: "delete",
          value: false,
          required: ["dataset:read"],
          disabled: false,
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "menu-ml-model",
      type: "operation",
      permissions: [
        {
          name: "model:read",
          type: "read",
          value: false,
          disabled: false,
          required: ["dataset:read"],
        },
        {
          name: "model:write",
          type: "write",
          value: false,
          required: ["dataset:read", "model:read"],
          disabled: false,
        },
        {
          name: "model:delete",
          type: "delete",
          value: false,
          required: ["dataset:read", "model:read"],
          disabled: false,
        },
        {
          name: null,
          type: "activate",
          value: null,
          disabled: false,
        },
      ],
    },
    {
      title: "menu-ml-inference",
      type: "operation",
      permissions: [
        {
          name: "inference:read",
          type: "read",
          value: false,
          disabled: false,
          required: ["dataset:read", "model:read"],
        },
        {
          name: "inference:write",
          type: "write",
          value: false,
          required: ["dataset:read", "model:read", "inference:read"],
          disabled: false,
        },
        {
          name: "inference:delete",
          type: "delete",
          value: false,
          required: ["dataset:read", "model:read", "inference:read"],
          disabled: false,
        },
        {
          name: "inference_status:write",
          type: "activate",
          value: null,
          required: ["dataset:read", "model:read", "inference:read"],
          disabled: false,
        },
      ],
    },
  ]
}

export function getPermissionsValue(permissionsRole = null) {
  const permissionsModel = _getPermissionsModel()
  permissionsModel.forEach(permission => {
    permission.permissions.forEach(item => {
      if (
        permissionsRole &&
        item.name !== null &&
        (permissionsRole.includes(item.name) || permissionsRole.includes("*"))
      ) {
        item.value = true
      }
    })
  })
  return permissionsModel
}
