export const CONSTANTS = {
  LOGIN_PAGE: "/",
  LOGGED_IN_INITIAL_PAGE: "/home",
}

export const INGEST_TYPES = {
  rt: "REAL_TIME",
  nrt: "NEAR_REAL_TIME",
  batch: "BATCH",
  metadata: "METADATA",
}
